<template>
  <Popup
    :header="
      mode === 'create' ? $t('message.add_user') : $t('message.update_user')
    "
  >
    <div v-if="state.isErrors" class="errors">
      <label class="error-text" v-for="(err, k) in state.errorSet" :key="k">{{
        err
      }}</label>
    </div>
    <div :class="['grid']">
      <form
        @submit.prevent="addEditClient"
        :class="state.isErrors ? 'form-has-errors' : ''"
      >
        <div class="grid gap">
          <div class="form-group">
            <input
              type="text"
              v-focus
              class="form-input"
              :placeholder="$t('message.name')"
              v-model="formData.name"
            />
            <label>{{ $t("message.name") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="grid gap">
          <div class="form-group">
            <input
              type="email"
              v-focus
              class="form-input"
              :placeholder="$t('message.mail')"
              v-model="formData.email"
            />
            <label>{{ $t("message.mail") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <div class="grid">
          <div class="form-group">
            <input
              type="password"
              class="form-input disabled"
              :placeholder="$t('message.password')"
              v-model="formData.password"
            />
            <!-- <input
              v-if="mode == 'edit'"
              type="password"
              v-focus
              class="form-input disabled"
              disabled
              placeholder="Password"
              radonly
              value="******"
            /> -->
            <label>{{ $t("message.password") }}</label>
          </div>
        </div>
        <div class="clearfix"></div>
        <br />
        <button class="form-btn" v-if="!form.loading">
          {{ $t("message.submit") }}
        </button>
        <button class="form-btn disabled" disabled v-if="form.loading">
          Submitting...
        </button>
      </form>
    </div>
  </Popup>
</template>

<script>
// import { ref } from '@vue/reactivity'
import Popup from "@/components/Popup.vue";
import { onMounted, reactive, ref } from "@vue/runtime-core";
import helper from "@/helper.js";
import { useStore } from "vuex";
import router from "@/router";
export default {
  components: { Popup },
  props: ["mode", "payload"],
  setup(props) {
    const form = ref({
      loading: false,
    });
    const state = reactive({
      isErrors: null,
      errorSet: [],
    });
    const store = useStore();
    const formData = reactive({
      parent_id: store.state.auth.user.id,
      name: props.mode === "create" ? null : props.payload.name,
      email: props.mode === "create" ? null : props.payload.email,
      password: props.mode === "create" ? null : props.payload.password,
    });
    const emitter = helper().emitter();
    onMounted(() => {
      emitter.emit("model-update"); 
    });
    // const popupTriggers = ref({
    //   trigger: false
    // })

    async function addEditClient() {
      state.errorSet = [];
      form.value.loading = true;
      if (props.mode === "edit") {
        // formData.user_id = props.payload.id;
        formData.user_id = props.payload.id;
      }
      await store
        .dispatch("account/addEditUser", {
          formData: formData,
          mode: props.mode,
        })
        .then((res) => {
          // store.commit("notification/SEND_NOTIFICATION", null);
          form.value.loading = false;
          let content = document.querySelector(".content");
          content.scrollTo(0, 0);
          if (res.status === "error") {
            state.isErrors = true;
            state.errorSet.push(res.message);
          }
          if (res.status === "success") {
            store.commit("notification/SEND_NOTIFICATION", res);
            state.isErrors = false;
            form.value.loading = false;
            emitter.emit("close-popup");
            router.push({ name: "ManageAccount" });
          }
        });
    }

    // const togglePopup = (trigger) => {
    //   popupTriggers.value['trigger'] = trigger
    // }
    // function updateModel() {
    // }

    return {
      // Popup,
      // popupTriggers,
      // togglePopup,
      // updateModel,
      // activePopup
      state,
      addEditClient,
      formData,
      form,
    };
  },
};
</script>
