<template>
  <div class="row-between">
    <h3 class="heading">{{ $t("message.manage_account") }}</h3>
    <button class="form-btn" @click="togglePopup('account', 'create', null)">
      +{{ $t("message.add_user") }}
    </button>
    <AccountFormPopup
      v-if="popupTriggers.popup == 'account'"
      :payload="popupTriggers.payload"
      :mode="popupTriggers.mode"
    ></AccountFormPopup>
  </div>
  <div class="clearfix"></div>
  <br />
  <div class="grid grid-3 center gap-lg" v-if="accounts.length >= 1">
    <div
      :class="[
        'card',
        'shadow',
        'regular',
        'text-center',
        account.is_blocked ? 'blocked' : '',
      ]"
      v-for="account in accounts"
      :key="account.id"
    >
      <img
        :src="require('@/assets/images/profile-avatar.png')"
        alt="Profile"
        class="card-avatar"
      />
      <h3>{{ account.name }}</h3>
      <h4 class="txt-gray">{{ account.email }}</h4>
      <button
        class="form-btn sm"
        @click="togglePopup('account', 'edit', account)"
      >
        {{ $t("message.edit") }}</button
      >&nbsp;
      <button
        :class="['form-btn', 'sm', 'outline']"
        @click="blockSubUser(account)"
      >
        {{ account.is_blocked ? $t("message.unblock") : $t("message.block") }}
      </button>
      &nbsp;
      <button class="form-btn sm danger" @click="deleteUser(account.id)">
        {{ $t("message.delete") }}
      </button>
    </div>
    <!-- <div class="grid-center">
      <div class="card sm shadow regular text-center">
        <img :src="require('@/assets/images/profile-avatar.png')" alt="Profile" class="card-avatar">
      </div>
    </div>
    <div class="grid-center">
      <div class="card sm shadow regular text-center">
        <img :src="require('@/assets/images/profile-avatar.png')" alt="Profile" class="card-avatar">
      </div>
    </div>
    <div class="grid-center">
      <div class="card sm shadow regular text-center">
        <img :src="require('@/assets/images/profile-avatar.png')" alt="Profile" class="card-avatar">
      </div>
    </div> -->
  </div>
  <div v-else>
    <h4 class="lbl-gray__empty">{{ $t("message.this_list_is_empty") }}</h4>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import AccountFormPopup from "./AccountFormPopup";
import helper from "@/helper";
import router from "@/router";
export default {
  components: { AccountFormPopup },
  setup() {
    const store = useStore();
    const popupTriggers = ref({
      popup: null,
      mode: "create",
      payload: null,
    });
    const emitter = helper().emitter();
    const accounts = computed(() => store.state.account.accounts);
    const togglePopup = async (popup, mode, payload) => {
      popupTriggers.value.popup = popup;
      popupTriggers.value.mode = mode;
      popupTriggers.value.payload = payload;
    };
    const blockSubUser = async (account) => {
      store.commit("notification/SEND_NOTIFICATION", null);
      let res = await store.dispatch("account/blockSubUser", account);
      if (res.status === "success") {
        let content = document.querySelector("#content");
        content.scrollTo(0, 0);
        store.commit("notification/SEND_NOTIFICATION", res);
      }
    };
    const deleteUser = async (id) => {
      store.commit("notification/SEND_NOTIFICATION", null);
      if (confirm("Delete this user?")) {
        let res = await store.dispatch("account/deleteSubUser", id);
        if (res.status === "success") {
          let content = document.querySelector("#content");
          content.scrollTo(0, 0);
          // state.isErrors = false
          router.push({ name: "ManageAccount" });
          // form.value.loading = false
          emitter.emit("close-popup");
          store.commit("notification/SEND_NOTIFICATION", res);
        }
      }
    };
    onMounted(async () => {
      if (!accounts.value?.length)
        await store.dispatch("account/fetchAllUsersAccount");
      store.commit("component/SET_LOADER", false);
      emitter.on("close-popup", () => {
        popupTriggers.value.popup = null;
      });
    });
    return {
      accounts,
      popupTriggers,
      togglePopup,
      blockSubUser,
      deleteUser,
    };
  },
};
</script>
